.modal-popup{
    &.confirm,
    &.no-title{
        .modal-header{
            padding-top: 40px;
        }
        .modal-title{
            display: none;
        }
    }
    &.no-footer{
        .modal-footer{
            display: none;
        }
        .modal-content{
            padding-bottom: 40px;
            @include m-down(md){
                padding-bottom: 20px;
            }
        }
    }

    &.confirm{
        .modal-inner-wrap{
            max-width: 450px;
        }
        .modal-content{
            font-size: 22px;
            padding-bottom: 40px;
            @include m-down(md){
                padding-bottom: 20px;
            }
        }
        .modal-footer{
            padding-bottom: 40px;
            text-align: right;
            button + button{
                margin-left: 16px;
            }
        }
        .action-primary,
        .action-secondary{
            @extend .action;
        }
        .action-primary{
            @extend .action.primary;
        }
    }

    &._inner-scroll{
        .modal-footer{
            padding-top: 12px;
            padding-bottom: 40px;
            @include m-down(md){
                padding-bottom: 20px;
            }
        }
    }
}


.modal-header,
.modal-content,
.modal-footer{
    padding-left: 40px;
    padding-right: 40px;
    @include m-down(md){
        padding-left: 20px;
        padding-right: 20px;
    }
}
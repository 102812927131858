.customer-container{
    box-shadow:0px 0px 40px rgba(177,177,177,0.40);
    .lable-text{
        letter-spacing: 0.24px;
    }
    .field-name-firstname, .field-name-lastname{
        .control {
            margin-bottom: 13px;
        }
    }
    @media (min-width: 768px){
        .field-name-firstname, .field-name-lastname{
            flex: 0 0 auto;
            width: 50%;
        }
    }
}
.actions-toolbar{
    &.border-top{
        border-top:1px $gray-18 solid;
    }
}
.sidebar-main{
    .account-nav{
        @include m-up(lg){
            margin-bottom: 50px;
            min-height: 500px;
        }
        @include m-down(lg){
            margin-bottom: 30px;
        }
        .account-nav-title{
            font-size: 16px;
            display: none;
            padding: 10px 16px;
            border: 1px solid $gray-4;
            &.active{
                svg{
                    transform: rotate(180deg);
                }
            }
            strong{
                font-weight: 400;
            }
            @include m-down(lg){
                display: block;
            }
        }
        .account-nav-content{
            &,
            &.active{
                display: block;
            }
            @include m-down(lg){
                display: none;
                border: 1px solid $gray-4;
                margin-top: -1px;
                padding: 0 15px;
                li {
                    strong,
                    a{
                        font-weight: 400 !important;
                        font-size: 15px !important;
                        text-transform: initial !important;
                        user-select: none;
                    }
                }
            }
            li{
                color: $gray-19;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 40px;
                font-weight: 700;
                a{
                    color: $black;
                    text-decoration: none;
                }
            }
        }
    }
}


// Tables
.orders-history,
.orders-recent{
    .data.table{
        td{
            border-bottom: 1px $gray-18 solid !important;
        }
        @include m-down(md){
            td{
                &.id,
                &.actions{
                    flex : 0 0 100%;
                }
                &.actions{
                    border-bottom: none !important;
                }
            }
        }
    }
}

.orders-history{
    .data.table{
        @include m-down(md){
            td{
                &.status{
                    flex : 0 0 100%;
                }
            }
        }
    }
}
.status-value{
    border: 1px solid $gray-4;
    width: 100px;
    @include m-down(sm){
        margin-left: 0 !important;
        margin-bottom: 10px !important;
    }
}
.order-print-logo{
    width: 160px;
    position: absolute;
    top: 10px;
}
.sales-order-print{
    .page-title-wrapper{
        padding-top:20px;
    }
    .status-value{
        margin: 10px 0 0 0 !important;
    }
    .order-date{
        margin-top: 10px;
    }
}
.sales-order-view{
    .order-print-logo{
        display: none;
    }
}
@include m-down(sm){
    .page-title-wrapper{
        .order-actions-toolbar{
            justify-content: start !important;
        }
    }
}

.order-links{
    .item{
        display: none;
    }
}
.checkout-onepage-success{
    .print{
        display: none;
    }
}
.account{
    .action{
        &.back{
            display: none;
        }
    }
}
.sales-order-view{
    .page-title-wrapper{
        display: flex;
        align-items: center;
        @include m-down(sm){
            display: block;
        }
        .page-title{
            white-space: nowrap;
            @include m-down(sm){
                text-align: left !important;
                margin-bottom: 10px !important;
            }
        }
    }
}
.offer-wishlist{
    border-radius: 0px;
    position: absolute;
    right: 10px;
    top:0px;
    text-align: center;
    z-index: 1;
    padding: 5px;
    height: 42px;
    width: 42px;
}

// .wishlist-list{
//     .product-item{
//         .product-item-info{
//             display: flex;
//             flex-wrap: wrap;
//             .product-item-photo{
//                 flex: 0 0 100%;
//             }
//         }
//     }
// }

.edit-address, .form-edit-account{
    .field-name-firstname, .field-name-lastname{
        flex: 0 0 auto;
        width: 50%;
        .label{
            display: none;
        }
        .control{
            margin-bottom: 8px;
        }
    }
    .field{
        .control{
            margin-bottom: 8px;
        }
    }
}
.edit-address-form {
    .country-input{
        order: 999;
    }
    .telephone-input{
        order: 1000;
    }
    .message.info{
        order: 1001;
    }
    .choice.set {
        order: 1002;
    }
}
.search-container{
    .search-list{
        max-width: 100%;
        margin-top: 8px;
    }
    .search-bar{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        input{
            flex: 0 0 calc(50% - 5px);
            min-width: auto;
            width: 50% !important;
            max-width: 50% !important;
        }
        .action{
            flex:0 0 calc(50% - 5px);
            order: 2;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.account-container{
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
}
.orders-history{
    .data{
        &.table{
            &:not(.plain){
                th{
                    padding-top: 0px;
                }
            }
        }
    }
}
.sales-order-print{
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: 30px 0 0 0;
}
@media print{
    .order-details-items{
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
        .data.table{
            display: table;
            width: 100%;
            font-size: 12px;
            tr{
                display: table-row;
            }
            th,td{
                display: table-cell;
                vertical-align: top;
                padding: 5px;
            }
            .name{
                strong{
                    font-weight: 400;
                }
            }
            *:before,
            *:after{
                display: none !important;
            }
            .subtotal,
            .shipping,
            .grand_total{
                th,td{
                    text-align: right !important;
                }
            }
            th,td{
                &.sku,
                &.price,
                &.qty,
                &.subtotal{
                    text-align: right !important;
                }
            }
        }
    }
    .block-title{
        display: none;
    }
    .block-order-details-view{
        display: table;
        padding: 0px !important;
        margin-top: 40px !important;
        .block-content{
            display: table-row;
            .box{
                padding: 10px !important;
                display: table-cell;
                flex: none !important;
                width: 25% !important;
            }
        }
    }
    .sales-order-print{
        .page-title-wrapper{
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            margin-top: 20px !important;
            margin-bottom: 30px !important;
            *{
                margin: 0 !important;
            }
        }
    }
}


.customer-account-create,
.customer-account-login,
.customer-account-forgotpassword {
    .page-title span{
        display:none;
    }
    .field-recaptcha{
        transform: scale(0.98);
        transform-origin: top left;
    }
}
th,td{
    &.col{
        flex: none;
    }
}
.table-bordered{
    border: 1px solid $gray-4;
    th,td{
        border: 1px solid $gray-4;
    }
}
.data.table:not(.plain){
    width: 100%;
    font-size: 14px;
    @include m-down(md){
        font-size: 16px;
    }
    line-height: 24px;
    margin-bottom: 24px;
    caption{
        display: none;
    }
    td,th{
        border-bottom: 1px solid $gray-18;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    th{
        color: $gray-7;
        &.actions{
            opacity: 0;
        }
    }
    .actions{
        text-align: right;
    }
    .action{
        padding: 0;
        color: $danger;
        font-size: 13px;
        font-weight: 700;
    }

    .action{
        & + .action{
            margin-left: 16px;
        }
        span{
            display: inline-block;
            vertical-align: middle;
        }
        &:before{
            height: 20px;
            width: 20px;
            content: '';
            display: inline-block;
            vertical-align: middle;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
        &.view{
            &::before{
                background-image: url('../images/svgs/converted-icon/icon-eye.svg');
            }
        }
        &.order{
            &::before{
                background-image: url('../images/svgs/converted-icon/icon-reorder.svg');
            }
        }
        &.print{
            &::before{
                background-image: url('../images/svgs/converted-icon/icon-print.svg');
            }
        }
    }
    tr{
        &.grand_total, &.shipping{
            text-align: right;
        }
    }

    @include m-down(md){
        th{
            display: none;
        }
        tr{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
            td{
                text-align: left !important;
                &:not(.actions){
                    &:before{
                        content: attr(data-th);
                        display: block;
                        font-weight: 700;
                        color: $gray-7;
                        margin-bottom: 8px;
                    }
                }
                flex : 0 0 50%;
            }
        }
    }
}
@include m-down(md){
    .account-container{
        .order-details-items{
            .data.table:not(.plain){
                tr{
                    td{
                        &:not(.actions){
                            &:before{
                                content: attr(data-th);
                                display: block;
                                font-weight: 700;
                                color: $gray-7;
                                margin-bottom: 8px;
                            }
                        }
                        flex: 0 0 100%;
                    }
                }
            }
        }
    }
}
td.status{
    >span{
        border: 1px solid $gray-4;
        width: 100px;
    }
    .pending{
        color: $gray-7;
        border-color: $gray-20;
    }
    .processing{
        color: $warm;
        border-color: $warm;
    }
    .complete{
        color: $success;
        border-color: $success;
    }
}


// Remove table toolbar
.table-wrapper + .toolbar{
    .toolbar-amount,
    .limiter{
        display: none;
    }
}
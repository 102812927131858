@import './fonts';
@import './bootstrap';
@import './mmenu/mmenu';
@import './include-media';
@import './slick';
@import './components/components';
@import './pages/page';

@import './misc';



/*
    Temp css
*/

.lazyload{
    background: url(../images/svgs/img-loader.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 50%;
}

//
.cms-brand{
    .column.main{
        > * {
            &:not(.brands-container){
                display: none;
            }
        }
    }
}
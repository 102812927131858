// Top header bar
.top-header-bar{
    min-height: 40px;
}

.header-user{
    border-bottom: 1px solid $gray-4;
}
.header-menu{
    border-bottom: 1px solid $gray-4;
    position: relative;
}
.menu-utility{
    height: 100%;
    > li{
        height: 100%;
        width: 46px;
        @include m-up(md){
            width: 57px;
            border-left: 1px solid $gray-4;
        }
        &.search-menu{
            .search-toggle-icon{
                svg.active{
                    display: none;
                }
            }
            @include m-up(lg){
                width: 100%;
            }
        }
    }
}

.search-container:not(.field){
    @include m-down(lg){
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        background: $white;
        height: 50px !important;
        border-top: 1px solid $gray-4;
    }
}
.search-open{
    .search-container{
        display: block;
        z-index: 1;
    }
    .search-toggle-icon{
        svg.default{
            display: none;
        }
        svg.active{
            display: block !important;
        }
    }
}

// Main mega menu container
.main-mega-menu-container{
    &,
    .mega-menu-box{
        @include m-down(md){
            display: none;
        }
    }
    @include m-down(md){
        background: $white;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        padding-top: 57px;
        overflow: auto;
    }
}
.menu-open{
    .main-mega-menu-container{
        display: block;
    }
}

.search-container{
    box-shadow: 8px 8px 40px 0 #00000014 !important;
    .minisearch .search-autocomplete{
        top: 55px;
        background: #fff;
        z-index: 2;
            // @media (max-width: 1300px) {
            //     max-width: 450px !important;
            //     width: auto !important;
            //     ul{
            //         width: 347px !important;
            //     }
            // }
        @media (max-width: 1024px) {
            top: 49px !important;
        }
        // @media (max-width: 576px) {
        //     width: 38px !important;
        // }
      }
} 
  .search-container .minisearch .search-autocomplete ul{
    list-style: none; 
    padding: 15px 0;
    box-shadow: 8px 8px 40px 0 rgba(0, 0, 0, 0.08) !important;
  }
  .search-container .minisearch .search-autocomplete ul li{
    padding: 8px 15px;
    cursor: pointer;
  }
  .search-container .minisearch .search-autocomplete ul li span.amount{
    font-weight: 600;
    margin-left: 10px;
  }


.has-children{
    a.active{
        svg{
            transform: rotate(180deg);
        }
    }
    @include m-up(md){
        &.active,
        &:hover{
            > a {
                box-shadow: 0 -15px 30px 0 rgba(0,0,0,0.10);
                position: relative;
                z-index: 9;
                background: $white;
            }
            .mega-menu-box{
                display: block;
            }
        }
    }
}
.mega-menu-box{
    display: none;
    z-index: 8;
    width: 100%;
    @include m-up(md){
        box-shadow: 5px 5px 60px 0 rgba(0,0,0,0.22);
        position: absolute;
        width: 100%;
        left: 0;
    }
    @include m-up(xl){
        &.brands-mega-menu-box,
        &.men-mega-menu-box{
            max-width: rem(1100);
        }
    }
    @include m-up(lg){
        &.newin-mega-menu-box{
            max-width: rem(900);
        }
        &.sale-mega-menu-box{
            max-width: rem(900);
        }
    }
    // @include m-up(md){
    //     &.newin-mega-menu-box{
    //         max-width: rem(500);
    //     }
    // }

    @include m-down(md){
        box-shadow: none !important;
        .bg-white{
            background: $gray-2 !important;
        }
        &.active{
            display: block !important;
        }
    }
}
.special-menu-item{
    min-height: 215px;
}


.mobile-contact{
    border-bottom: 1px solid $gray-4;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
}

// Sticky menu
.sticky-menu{
    .page-header{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        box-shadow: 0 0 40px 0 rgba($black,0.3);
        border-bottom: none;
        z-index: 5;
    }
    @include m-up(md){
        // .top-header-bar,
        .header-user{
            display: none !important;
        }
    }

    // .logo-container{
    //     @include m-up(md){
    //         visibility: hidden;
    //     }
    // }
}
a,button{
    &.action,
    &.button{
        display: inline-block;
        text-decoration: none;
        color: $black;
        font-size: rem(14);
        line-height: rem(17);
        width: auto;
        &.plain{
            &,
            &:hover,
            &:focus,
            &:active{
                background: none;
                border: none;
            }
        }

        &:not(.plain){
            text-transform: uppercase;
            border-radius: 100px;
            border: 2px solid transparent;
            padding: 15px 48px;
            text-align: center;
            font-weight: 700;
        }
        &.primary{
            background: $danger;
            color: $white;
        }
        &.white{
            color: $black;
            background: $white;
            &:hover{
                color: $white;
                background: $black;
            }
        }
        &.black{
            color: $white;
            background: $black;
            &:hover{
                color: $black;
                background: $white;
            }
        }

        &.outline{
            background: transparent;
            &.primary{
                border-color: $danger;
                color: $danger;
                &:hover{
                    background: $danger;
                    color: $white;
                }
            }
            &.white{
                border-color: $white;
                color: $white;
                &:hover{
                    background: $white;
                    color: $black;
                }
            }
            &.black{
                border-color: $black;
                color: $black;
                &:hover{
                    background: $black;
                    color: $white;
                }
            }
        }
    }
    &.disabled,
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.catalog-product-view{
    .message-error{
        @include m-down(md){
            position: fixed;
            width: calc(100% - 40px);
            z-index: 9;
        }
    }
    .message.success{
        display: none !important;
    }
}
.product-detail-holder{
    @include m-up(lg){
        &:before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 50%;
            background: $white;
        }
    }
}
.product-detail-container{
    @include m-in(md,lg){
        position: relative;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: -50px;
            bottom: 0;
            background: $white;
            z-index: 1;
        }
        > *{
            position: relative;
            z-index: 2;
        }
    }
    @include m-up(lg){
        position: relative;
        right: 0;
        &.sticky{
            position: fixed;
            left: 50%;
            top: 50px;
            &.sticky-bottom{
                top: inherit;
            }
        }
    }
    .add-to-cart-box{
        div.mage-error{
            padding-left: 0;
        }
        @include m-up(md){
            max-width: 350px;
        }
        select,
        .input-text{
            @include m-down(md){
                background-color: $white;
            }
        }
    }
    .product-add-form{
        form{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
        }
        .field{
            flex: 0 0 calc(50% - 10px);
        }
    }
}

// Product detail price
.product-price-detail{
    .price-box{
        align-items: flex-end;
        flex-direction: row;
        span{
            display: block;
            line-height: 1;
            &.price-label{
                display: none;
            }
        }
    }
    .old-price{
        order: 1;
        font-size: 14px;
        display: block !important;
        padding-right: 10px;
        margin-bottom: -1px;
        margin-right: 0 !important;
    }
    .normal-price{
        order: 2;
        font-size: 22px;
        font-weight: 700;
        padding-right: 10px;
        color: $danger;
        margin-bottom: -2px;
    }
}

.product-detail-tabs{
    ul{
        list-style-type: circle;
    }
    .data.switch{
        color: $black;
    }
    .active{
        .data.switch{
            color: $gray-6;
            border-bottom: 1px solid $gray-6;
        }
    }
    .mesure-thumb{
        @include m-down(md){
            max-width: 200px;
            margin: 0 auto;
        }
    }
    .mesuredesc-row + .mesuredesc-row{
        padding-top: 16px;
        border-top: 1px dotted $gray-4;
    }
}

.product-info-stock-sku{
    .configurable-variation-qty{
        display: none !important;
    }
}
.qty{
    .qty-changer{
        height: 54px;
        width: 54px;
        top: 0;
        &.increment{
            right: 0;
        }
        &.decrement{
            left: 0;
        }
    }
}

.product-detail-breadcrumb-container{
    height: 30px;
    overflow: hidden;
    .breadcrumbs .items{
        flex-wrap: nowrap;
        flex-shrink: 0;
        white-space: nowrap;
    }
}

.product-confirmation-modal{
    .main-title{
        border: 1px solid $success;
    }
    .modal-inner-wrap{
        width: 780px;
        @include m-down(lg){
            width: 700px;
        }
        @include m-down(md){
            width: calc(100% - 40px);
        }
    }
    .cart-items-list{
        .cart-item{
            border-bottom: 1px solid $gray-4;
        }
    }
    .custom-cart-item-list{
        @include m-up(md){
            margin-right: -20px;
            padding-right: 20px;
            height: 89px;
            @include customScroll();
            overflow: auto;
        }
    }
}

.confirm-product-item-box{
    border: 1px solid $gray-4;
}
.brands-container{
    .brands-filters{
        .input-box{
            display: none;
        }
        .letters{
            .letters-box{
                a{
                    border:2px transparent solid;
                    padding: 15px 14px;
                    @include m-down(lg) {
                        padding: 0px 14px;
                    }
                    margin: 0;
                    &.-active{
                        border-color: $danger;
                    }
                }
            }
        }
    }
    .content{
        .brands-letter{
            border-bottom: 1px $gray-4 solid;
            &:last-child{
                border-bottom: none;
            }
            .brand-item{
                width: 20%;
                @include m-down(lg) {
                    width: 25%;
                }
                @include m-down(md) {
                    width: 50%;
                }
            }
        }
    }
}
.img-fluid{
    max-width: 100%;
}
.product-image-container{
    padding-bottom: 121%;
    &.ratio-1-1{
        padding-bottom: 100%;
    }
    .product-image-wrapper{
        top: 0;
        left: 0;
    }
}
.img-container {
    position: relative;
    padding-top: 66%;
    overflow: hidden;
}

.img-container img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    object-fit: cover;
    // height: 100%;
    // width: 100%;
}


.img-contain{
    object-fit: contain;
}
.blog-content{
    p{
        margin-bottom: 30px;
    }
    h2{
        font-size: 28px;
        line-height:44px;
        font-weight: 700;
        margin-bottom: 50px;
        @include m-down(md) {
            font-size: 21px;
            line-height: 26px;
            margin-bottom: 35px;
        }
    }
    h3{
        margin-bottom: 50px;
    }
    img{
        width: 100%;
        height: auto;
        margin-bottom: 18px;
    }
    @include m-down(lg) {
        img{
            padding: 0 33px;
        }
    }
    @include m-down(md) {
        img{
            padding: 0px;
        }
    }
}

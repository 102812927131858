.minicart-wrapper{
    .showcart{
        &.active{
            box-shadow : -10px -10px 10px 0 rgba(0, 0, 0, 0.08) !important;
            position: relative;
            z-index: 99;
        }
    }
    .counter{
        position: static !important;
    }
    .loading-mask {
        background: rgba($white,0.9);
        .loader{
            height: 30px;
            width: 30px;
            &::before{
                height: 100%;
                width: 100%;
            }
            img{
                width: 30px;
            }
        }
    }
}
.counter-number{
    background: $danger;
    line-height: 20px;
    width: 20px;
    display: block;
    @extend .rounded-50;
    position: absolute;
    top: 5px;
    right: 5px;
}

.minicart-menu{
    .ui-dialog{
        // display: block !important;
        position: absolute;
        right: 0;
        top: 100%;
        width: calc(33.33vw - 6px);
        min-width: 478px;
        margin-top: 1px;
        z-index: 5;
    }
}

.minicart-items-wrapper{
    height: auto !important;
    max-height: 310px !important;
    overflow: auto;
    @include customScroll();
    // padding-right: 20px;
    // margin-right: -20px;
}
.minicart-items{
    .product-item{
        border-bottom: 1px solid $gray-4;
        &:last-child{
            border:none;
            margin-bottom: 0 !important;
        }
    }
    &.cart-items{
        .product-item{
            &:last-child{
                padding-bottom: 0 !important;
            }
        }
    }
    .product-item-photo{
        width: 78px;
        @include m-down(340px){
            width: 60px;
        }
    }
    .product-image-container,
    .product-image-wrapper,
    .product-image-photo{
        &:not(.initial-props){
            box-shadow: none !important;
        }
        padding: 0 !important;
        display: block !important;
        position: static !important;
        width: 100% !important;
    }

    .product-image-photo{
        width: 100% !important;
    }
    input:not(.plain){
        height: 37px;
        line-height: 37px;
    }
    .details-qty{
        .control{
            width: 116px;
        }
    }
    .qty .qty-changer{
        height: 37px;
        width: 37px;
    }
    .product-item-name{
        // min-height: 52px;
        overflow: hidden;
    }

    .actions-toolbar{
        .action-delete{
            @include m-down(md){
                svg{
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}
.summary-container{
    border-bottom: 1px solid $gray-4;
    border-top: 1px solid $gray-4;
}


.minicart-wrapper{
    @include m-down(md){
        position: static !important;
        .ui-dialog{
            right: 0;
            left: 0;
            width: 100%;
            min-width: inherit !important;
        }
        .minicart-items-wrapper{
            overflow-x:  hidden !important;
        }
    }
}


.subtitle.empty{
    text-align: center;
    width: 100%;
    display: block;
    ~ * {
        display: none !important;
    }
}
.mobile-home-banner{
    height: calc(100vh - 53px);
    .shop-sale-button{
        margin-bottom: 150px;
    }
    @media screen and (max-width: 767px) and (orientation: landscape){
        .shop-sale-button{
            margin-bottom: 0;
        }
    //     padding-top: 100%;
    //     height: auto !important;
    //     .shop-sale-button{
    //         margin-bottom: 0;
    //     }
        .content{
            // position: absolute;
            // top: 0;
            // left: 0;
            // right: 0;
            // height: calc(100vh - 53px) !important;
            justify-content: center !important;
        }
    }
}
.cms-home{
    .page-title-wrapper{
        display: none;
    }
}
.cms-home, .page-with-filter{
    .product-item{
        .product-item-photo{
            .cc-best-seller-48467917{
                display: none;
            }
        }
    }
}
.banner-box{
    padding-top: 48%;
    width: 100%;
    @include m-down(md){
        min-height: 200px;
    }
    @include m-down(sm){
        min-height: 185px;
    }
    @include m-up(md){
        padding-top: 112%;
    }
    .banner-box-content{
        top: 0;
        left: 0;
        height: 100%;
    }
}

.usp-bar{
    .usp-box{
        height: 64px;
        @include m-up(lg){
            height: 80px;
        }
    }
    .icn-box{
        width: 60px;
        @include m-in(md,lg){
            width: 45px;
        }
    }
}

.home-section-2{
    @include m-up(md) {
        .box-1{
            height: 360px;
        }
    }
    @include m-up(lg) {
        .box-1{
            height: 540px;
        }
    }
    .slick-dots{
        @include m-up(lg) {
            margin-bottom: 40px;
        }
    }
}

.home-section-3{
    .box{
        background: $gray-5;
        position: relative;
        padding-top: 100%;
        &.wide{
            height: 100%;
            @include m-up(md){
                padding-top: 0;
            }
        }
        a{
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}

// Social section
.social-section{
    height: 252px;
    background-size: auto 252px;
    background-position: center;
    @include m-up(md){
        height: 391px;
        background-size: auto 391px;
    }
    @include m-up(lg){
        height: 370px;
        background-size: auto 370px;
    }
    &.lazyloaded{
        background-image: url(../images/social-section-bg.jpg);
    }
    li{
        a{
            height: 39px;
            width: 39px;
            @include m-up(md){
                height: 49px;
                width: 49px;
            }
            @include m-up(lg){
                height: 46px;
                width: 46px;
            }
        }
    }
}


// Home page product section
.home-product-section{
    .product-tab-container{
        @include m-up(lg){
            padding-bottom: 55px;
        }
    }
    .home-product-slider-item{
        .product-item{
            display: none;
            &.active{
                display: block;
            }
        }
        &:not(.slider-initialized){
            .product-item{
                &.count-1,
                &.count-2{
                    display: block;
                }
                @include m-up(md){
                    &.count-3{
                        display: block;
                    }
                }
            }
        }
        // product-number-<?php echo $counter;?>
    }

}
@include m-down(md){
    .blog-detail-home{
        height: 70px;
        overflow: hidden;
    }
}


.breadcrumbs{
    .items{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        *{
            text-decoration: none;
            font-weight: 400;
            color: $gray-6;
            font-size: 15px;
            line-height: 30px;
        }
        a{
            &:hover{
                color: $black;
            }
        }
        li{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            &:after{
                content: '';
                width: 5px;
                height: 5px;
                border: 1px solid $gray-6;
                border-radius: 50%;
                display: block;
                margin: 0 10px;
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
        }
    }
}

body:not(.page-product-configurable){
    .breadcrumbs{
        padding:8px 20px;
        @include m-up(lg){
            padding:8px 40px;
            margin-bottom: 10px;
        }
    }
}
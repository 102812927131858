@function rem($value) {
  $remValue: ($value) * 1px;
  // $remValue: ($value / 16) * 1rem;
  @return $remValue;
}

// Font-family Css Start
$font-family-roboto:      'Inter', sans-serif;

$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$variable-prefix:             apt-;
// stylelint-enable value-keyword-case
$font-family-base:            'Inter', sans-serif;

$enable-negative-margins:     true;
// $enable-important-utilities : false;

//Color Css Start
$white        : #FFFFFF;
$gray-1       : #F8F8F8;
$gray-2       : #F4F4F4;
$gray-3       : #DADADA;
$gray-4       : #D1D1D1;
$gray-5       : #A5A5A5;
$gray-6       : #878787;
$gray-7       : #666666;
$gray-8       : #4C4C4C;
$gray-9       : #8C8C8C;
$gray-10      : #D3D3D3;
$gray-11      : #929292;
$gray-12      : #AEAEAE;
$gray-13      : #D5D5D5;
$gray-14      : #A3A3A3;
$gray-15      : #757575;
$gray-16      : #e7e7e7;
$gray-17      : #eaeaea;
$gray-18      : #BCBCBC;
$gray-19      : #6C6C6C;
$gray-20      : #BEBEBE;
$black        : #000000;
$danger       : #d33759;
$success      : #58b0aa;


// Undefined colors
$info       : #007dc3;
$warning    : #ffc000;
$warm       : #ff7d24;

$text-colors : (
  white       : $white,
  gray-1      : $gray-1,
  gray-2      : $gray-2,
  gray-3      : $gray-3,
  gray-4      : $gray-4,
  gray-5      : $gray-5,
  gray-6      : $gray-6,
  gray-7      : $gray-7,
  gray-8      : $gray-8,
  gray-9      : $gray-9,
  gray-10     : $gray-10,
  gray-11     : $gray-11,
  gray-15     : $gray-15,
  gray-17     : $gray-17,
  black       : $black ,
  danger      : $danger,
  success     : $success,
);

$bg-color : $text-colors;
$border-colors : $text-colors;
$body-color : $black;

// Typography variables
$font-size-base:  0.875rem;
$h1   :  rem(36);
$h2   :  rem(30);
$h3   :  rem(24);
$h4   :  rem(21);
$h5   :  rem(16);
$h6   :  rem(14);
$h7   :  rem(12);

$font-sizes: (
  12   : rem(12),
  13   : rem(13),
  14   : rem(14),
  15   : rem(15),
  16   : rem(16),
  18   : rem(18),
  21   : rem(21),
  22   : rem(22),
  24   : rem(24),
  26   : rem(26),
  28   : rem(28),
  30   : rem(30),
  32   : rem(32),
  36   : rem(36),
  46   : rem(46),
);
$line-heights : (
  17 : rem(17),
  14 : rem(14),
  16 : rem(16),
  19 : rem(19),
  21 : rem(21),
  24 : rem(24),
  26 : rem(26),
  28 : rem(28),
  30 : rem(30),
  32 : rem(32),
  35 : rem(35),
  36 : rem(36),
  38 : rem(38),
  44 : rem(44),
  56 : rem(56),
);
$font-weights : (
  100  : 100,
  200  : 200,
  300  : 300,
  400  : 400,
  500  : 500,
  600  : 600,
  700  : 700,
  800  : 800,
  900  : 900,
);


$container-max-widths: ();



// $enable-negative-margins:     true;
$spacer: 0.5rem ;
$spacers: (
  0: 0,
  2: rem(2),
  3: rem(3),
  5: rem(5),
  8: rem(8),
  10: rem(10),
  12: rem(12),
  13: rem(13),
  15: rem(15),
  16: rem(16),
  20: rem(20),
  25: rem(25),
  30: rem(30),
  35: rem(35),
  40: rem(40),
  50: rem(50),
  60: rem(60),
  70: rem(70),
  80: rem(80),
  90: rem(90),
);

$border-radiuses: (
  0: 0,
  2: rem(2),
  5: rem(5),
  8: rem(8),
  50 : 50%
);

$grid-gutter-width: 1.6rem;
$gutters : $spacers;
// $gutters: (
//   0: 0,
//   1: 1.6rem,
//   2: 3.2rem,
//   3: 7rem,
// );

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1300px
);


// Shadows
$shadows : (
  sm : rem(5) rem(5) rem(10) 0 rgba($black,0.05),
  null : rem(8) rem(8) rem(40) 0 rgba($black,0.08),
  lg : rem(5) rem(5) rem(60) 0 rgba($black,0.22),
  1: rem(3) rem(3) rem(30) 0 rgba($black,0.18),
  2 : rem(5) rem(5) rem(40) 0 rgba($black,0.15),
);


@mixin customScroll(){
  &::-webkit-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar-track {
    background: $gray-13;
  }
  &::-webkit-scrollbar-thumb {
    background: $black;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}
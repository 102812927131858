$utilities: map-merge(
  $utilities,
  (
    "gap": null,
    "float": null,
    // "shadow": null,
    "top": null,
    "bottom" : null,
    "start" : null,
    "end" : null,
    "translate-middle" : null,
    "border" : null,
    "border-top" : null,
    "border-end" : null,
    "border-bottom" : null,
    "border-start" : null,
    "border-width" : null,
    "viewport-width" : null,
    "min-viewport-width" : null,
    "viewport-height" : null,
    "min-viewport-height" : null,
    "gradient" : null,
    "user-select" : null,
    "font-family" : null,
    "align-content" : null,
  )
);

$utilities: map-merge(
  $utilities,
  (
    // Typo graphy
    "font-size": map-merge(
      map-get($utilities, "font-size"),
      (
        responsive: true,
        property: font-size,
        class: fs,
        values: $font-sizes,
        rfs : false
      ),
    ),
    "font-weight": map-merge(
      map-get($utilities, "font-weight"),
      (
        responsive: true,
        property: font-weight,
        class: fw,
        values: $font-weights
      ),
    ),
    "line-height": map-merge(
      map-get($utilities, "line-height"),
      (
        responsive: true,
        property: line-height,
        class: lh,
        values: $line-heights,
      ),
    ),
    "color": map-merge(
      map-get($utilities, "color"),
      (
        property: color,
        class: text,
        values: $text-colors
      ),
    ),

    // Border color
    "border-color": map-merge(
      map-get($utilities, "border-color"),(
        property: border-color,
        class: border,
        values: $border-colors,
      )
    ),
    "rounded": map-merge(
      map-get($utilities, "border-color"),(
        property: border-radius,
        class: rounded,
        values: $border-radiuses,
      )
    ),
    "text-decoration": map-merge(
      map-get($utilities, "text-decoration"),(
        property: text-decoration,
        class: td,
        values: (
          none: none,
          underline: underline,
          strike: line-through,
        ),
      ),
    ),
    "text-transform": map-merge(
      map-get($utilities, "text-transform"),(
        property: text-transform,
        class: tt
      ),
    ),

    // Backgrounds & colors
    "background-color": map-merge(
      map-get($utilities, "background-color"),(
        property: background-color,
        class: bg,
        responsive: true,
        values: map-merge($bg-color,(
            "transparent": transparent
          )
        )
      )
    ),
    "background-size": (
      property: background-size,
      class: bg,
      values: (
        cover : cover,
        contain : contain,
      )
    ),
    "background-position": (
      property: background-position,
      class: bg,
      values: (
        center: center,
        'top-center': top center,
      )
    ),
    "background-repeat": (
      property: background-repeat,
      class: bgr,
      values: (
        norepeat: no-repeat,
        repeat: repeat,
      )
    ),

    // Modify utility
    // Renaming flex utilities
    "justify-content": map-merge(
      map-get($utilities, "justify-content"),
      ( class: jc ),
    ),
    "align-items": map-merge(
      map-get($utilities, "align-items"),
      ( class: ai ),
    ),
    "align-self": map-merge(
      map-get($utilities, "align-self"),
      ( class: as ),
    ),
    "position": map-merge(
      map-get($utilities, "position"),
      ( class: pos ),
    ),
    "shadow": map-merge(
      map-get($utilities, "shadow"),
      (
        class: shadow ,
        values: $shadows
      ),
    ),
    "width": map-merge(
      map-get($utilities, "width"),
      (
        responsive : true,
      ),
    ),
    // Misc
    "text-shadow": (
      property: text-shadow,
      class: ts,
      values: (
        null: rem(1) rem(2) rgba($black,0.15),
      )
    ),
    "cursor": (
      property: cursor,
      class: '',
      values: (
        pointer: pointer,
      )
    ),
  ),
);
.checkout-index-index,.paypal-express-review{
    // Hide summary in mobile
    .mobile-summary-toggle{
        .toggle-icon{
            @include m-down(md){
                display: inline-block;
                vertical-align: middle;
                transform: rotate(180deg);
            }
        }
    }
    &:not(.show-summary){
        .cart-total .totals:not(.grand),
        .totals-tax,
        #mobile-summary-container{
            @include m-down(md){
                display: none;
            }
        }
        .mobile-summary-toggle{
            .toggle-icon{
                @include m-down(md){
                    transform: rotate(0deg);
                }
            }
        }
    }
}
.checkout-index-index{
    .field-tooltip.toggle{
        &:not(.what-is-cvv){
            display: none;
        }
    }
    .page-title-wrapper{
        display: none;
    }
    .action.primary.checkout{
        width: 100%;
        margin-top: 16px;
        padding: 15px 40px;
    }
    &,input,select,textarea{
        @include m-up(md){
            font-size: 14px;
        }
    }
}
.checkout-header{
    border-bottom: 1px solid $gray-4;
}

.checkout-header{
    .dropdown-overlay{
        background: rgba($black,0.3);
    }

    .modal-header{
        .action-close{
            margin: 18px 12px;
        }
    }
}
#opc-sidebar{
    .items-container{
        > div{
            @include m-up(md){
                max-height: 364px;
                overflow: auto;
                @include customScroll();
            }
        }
    }
}
.opc-progress-bar{
    li{
        color: $gray-15;
        @include m-down(sm){
            + li {
                margin-left: 16px;
            }
        }
        &::before{
            width: 37px;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            flex-shrink: 0;
            background: $gray-17;
            color: $gray-15;
        }
        &._complete,
        &._active{
            color: $black;
            &:before{
                background: $danger;
                color: $white;
            }
        }
        svg{
            @include m-down(sm){
                display: none;
            }
        }
        &:last-child{
            svg{
                display: none;
            }
        }
    }
    li:nth-child(1){
        &:before{
            content: '1';
        }
    }
    li:nth-child(2){
        &:before{
            content: '2';
        }
    }
    li:nth-child(3){
        &:before{
            content: '3';
        }
    }
}
#opc-new-shipping-address,
.opc-wrapper{
    select,
    .input-text{
        &:not(.plain){
            height: 40px;
            line-height: 40px;
        }
    }
    .control{
        margin-bottom: 8px;
    }
    .field {
        > .label{
            display: none;
            // opacity: 0.6;
            // font-size: 12px;
            // line-height: 1.2;
            // margin-bottom: 5px;
        }
        input + .label{
            display: block;
        }
    }
    .opc{
        list-style-type: none;
    }
}
#opc-new-shipping-address,
.billing-address-form,
#shipping-new-address-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div:empty{
        display: none;
    }
    .field{
        flex : 0 0 100%;
        // margin-bottom: 10px;
    }
    fieldset.field{
        margin-bottom: 0;
    }
    .field{
        &.street{
            label,
            .label{
                display: none !important;
            }
            > .control{
                margin-bottom: 0;
            }
        }
    }
    .search-container .search-bar,
    .shipping_cp_address_class:not(.label){
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .search-bar .input-text,
    .search-bar .action,
    .shipping_cp_address_class > .field,
    // .shippingAddress-country_id,
    // .shippingAddress-postcode,
    // .shippingAddress-city,
    // .shippingAddress-telephone,
    .shippingAddress-lastname,
    .shippingAddress-firstname,
    // .billingAddressbraintree-city,
    .billingAddressbraintree-region_id,
    // .billingAddressbraintree-telephone,
    .billingAddressbraintree-region,
    .billingAddressbraintree-lastname,
    .billingAddressbraintree-firstname{
        flex : 0 0 calc(50% - 5px);
        // @include m-down(sm){
        //     flex : 0 0 100%;
        // }
    }
    .search-container{
        .search-list{
            select{
                margin-top: 8px;
            }
        }
        .action{
            padding: 0;
            width: 100%;
            height: 40px;
            // border-radius: 0;
        }
    }


    .billingAddressbraintree-country_id,
    .shippingAddress-country_id{
        order: 999;
    }
    .shippingAddress-telephone,
    .billingAddressbraintree-telephone{
        order: 1000;
    }
    .choice.field {
        order: 2000;
    }
    .shippingAddress-city,
    .billingAddress-city,
    .billingAddressbraintree-city,
    .shippingAddress-region,
    .shippingAddress-company{
        > .label{
            display: none !important;
        }
    }
}
#opc-new-shipping-address,
.checkout-billing-address{
    form{
        width: 100%;
    }
    .fieldset.address{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.shipping-address-item{
    > div{
        border: 2px solid transparent;
    }
    &.selected-item{
        > div{
            border: 2px solid $danger;
        }
    }
}

.table-checkout-shipping-method{
    width: 100%;
}

.checkout-payment-method{
    .payment-method-title{
        font-weight: 700;
        margin-bottom: 16px;
    }
    .payment-method{
        .payment-method-content{
            display: none;
            margin-bottom: 24px;
        }
        &._active{
            .payment-method-content{
                display: block;
            }
        }
    }

    .payment-method-billing-address{
        margin-bottom: 16px;
        .field.choice{
            margin-bottom: 16px;
        }
    }
}


.opc-payment-additional.discount-code,
.opc-block-shipping-information{
    display: none;
}


.payment-method-braintree .hosted-control{
    background-color: $gray-1;
    height: 50px;
    line-height: 50px;
    border: 1px solid $gray-14;
    padding: 0 16px;
}

.payment-method#payment-method-braintree-paypal,
#paypal_express,
#paypal_express + label{
    display: none !important;
}

.what-is-cvv{
    position: relative;
    z-index: 99;
    .field-tooltip-action.action-cvv{
        user-select: none;
        cursor: help;
    }
    .backdrop{
        position: fixed;
        background: rgba($black,0.5);
        top: 0 !important;
        left: 0 !important;
        bottom: 0;
        right: 0;
    }
    .field-tooltip-content-container{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 99;
        display: none;
        img{
            background: $white;
            max-width: 100%;
        }
    }
    .field-tooltip-content-data{
        max-width: 500px;
        margin: 0 auto;
    }
    .close-cvv-help{
        top: 5px;
        left: 5px;
    }
    &.active{
        .field-tooltip-content-container{
            display: flex;
        }
    }
}
.checkout-cart-index klarna-placement{
    display: block;
    order: 1;
    margin-top: 25px;
    .container{
        width: 250px;
        border-radius: 50px;
        margin-left: auto;
        height: 51px;
        min-height: 51px;
        .content-container{
            padding: 0 !important;
            height: 51px;
            .content{
                padding-right: 5px;
                .heading{
                    animation: none;
                    opacity: 1;
                    font-size: 15px;
                }
                .logo-container svg{
                    width: 70px;
                    height: 30px;
                }
            }
        }
    }
}
.page-footer{
    .form.subscribe{
        position: relative;
        .input-text{
            height: 54px;
        }
        .action{
            position: absolute;
            top: 0;
            right: 0;
            height: 54px;
            width: 54px;
        }
    }
}
.ui-slider {
	position: relative;
	text-align: left;
	.ui-slider-handle {
		position: absolute;
		z-index: 2;
		cursor: default;
	}
}
.ui-slider-horizontal {
	height: 2px;
	border: none;
	background: rgba($black,0.2);
    margin-top: 20px;
    margin-bottom: 20px;
    .ui-slider-range{
        background: $black;
        height: 100%;
        position: absolute;
    }
	.ui-slider-handle {
        top: 0;
        transform: translateY(calc(-50% + 1px));
        height: 20px;
        width: 20px;
        -webkit-border-radius: 20px;
        border-radius: 20px;
        background: $black;
        display: block;
        position: absolute;
        + .ui-slider-handle {
            transform: translate(-100%,calc(-50% + 1px));
        }
    }
}


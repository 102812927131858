
.lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

//
// Lists
//

.list-unstyled {
  margin: 0;
  padding: 0;
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  margin: 0;
  padding: 0;
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;
  margin: 0;
  padding: 0;
  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}
@import './home';
@import './product-listing';
@import './search-result';
@import './product-detail';
@import './brands';
@import './blog';
@import './contact';
@import './cart';
@import './paypal-review';
@import './customer-account';
// @import './logout';

@import './checkout';

@import './cms';
/* Slider */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	user-select: none;
	touch-action: pan-y;
	.slick-track {
		transform: translate3d(0, 0, 0);
	}
	.slick-list {
		transform: translate3d(0, 0, 0);
	}
}
.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
	&:focus {
		outline: none;
	}
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}
.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
	&:before {
		display: table;
		content: '';
	}
	&:after {
		display: table;
		content: '';
		clear: both;
	}
}
.slick-loading {
	.slick-track {
		visibility: hidden;
	}
	.slick-slide {
		visibility: hidden;
	}
}
.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
	img {
		display: block;
	}
}
[dir='rtl'] {
	.slick-slide {
		float: right;
	}
}
.slick-slide.slick-loading {
	img {
		display: none;
	}
}
.slick-slide.dragging {
	img {
		pointer-events: none;
	}
}
.slick-initialized {
	.slick-slide {
		display: block;
	}
}
.slick-vertical {
	.slick-slide {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}
.slick-arrow.slick-hidden {
	display: none;
}


.slider{
	position: relative;
	&:not(.slick-initialized){
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		overflow: hidden;
	}
	&.same-height{
		align-items: stretch;
		&,
		.slick-list,
		.slick-track,
		.slick-slide,
		.slick-slide > div{
			height: 100%;
		}
	}
}

.slick-dots{
    position: absolute;
	left: 0;
	bottom: 0;
	@extend .list-unstyled;
	text-align: center;
	width: 100%;
	li{
		display: inline-block;
		vertical-align: middle;
	}
	button{
		padding: 0;
		margin: 0;
		background: none;
		border: none;
		font-size: 0;
		height: 8px;
		width: 8px;
		border-radius: 50%;
		background-color: $black;
		margin: 0 2px;
	}
	.slick-active{
		button{
			background-color: $gray-10;
		}
	}
}
.price-label{
    display: none;
}
.price-box{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > span + span{
        margin-right: 8px;
    }
    @include m-up(md){
        flex-direction: column;
        align-items: flex-end;
        font-size: 15px;
        line-height: 22px;
        > span + span{
            margin: 0;
        }
    }
    .old-price{
        order: 1;
        color: $gray-6;
        text-decoration: line-through;
    }
    .normal-price,
    .special-price{
        order: 2;
        font-weight: 700;
    }

}
.catalogsearch-result-index{
    .columns{
        display: flex;
        flex-direction: column;
        .main{
            order: 2;
        }
        .sidebar{
            order: 1;
        }
    }
}
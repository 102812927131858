.cms-page-view:not(.cms-contact-us):not(.cms-brand) {
    .page-title {
        font-weight: 700;
        margin-bottom: 30px;
    }
    .page-main {
        max-width: 700px;
        margin: 0 auto;
        padding: 0 20px;
    }
}
.pages{
    .items{
        .item{
            border-radius: 100%;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border:1px transparent solid;
        }
    }
}
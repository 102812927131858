label{
    user-select: none;
    cursor: pointer;
}
input{
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    }
    &[type=number] {
    -moz-appearance: textfield;
    }
}

input,
select,
textarea{
    border-radius: 0;
    box-shadow: none !important;
    -webkit-appearance: none;
}

select,
.input-text{
    width: 100%;
    &.plain{
        border: none;
    }
    &:not(.plain){
        background-color: $gray-1;
        height: 54px;
        line-height: 54px;
        border: 1px solid $gray-14;
        padding: 0 16px;
    }
    &.mage-error{
        &,
        &:focus{
            border-color: $danger !important;
        }
    }
}
._error{
    select,
    .input-text{
        &,&:focus{
            border-color: $danger !important;
        }
    }
}
select{
    cursor: pointer;
    &:not(.plain){
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-right: 48px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 8'%3E%3Cpath d='M8 7.9L.4.4l.8-.8L8 6.5l6.8-6.9.8.8z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 18px 9px;
        background-position: calc(100% - 18px) center ;
    }
}

textarea.input-text:not(.plain){
    height: 100px;
    line-height: 1.2;
    padding-top: 8px;
    padding-bottom: 8px;
}

// Error
input + .mage-error,
div.mage-error{
    color: $danger;
    // padding-left: 20px;
    font-size: 15px;
    line-height: 19px;
    margin-top: 7px;
}


// Form fields modifications
.field{
    > .label{
        // font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
    }
}
.field{
    .control {
        margin-bottom: 13px;
    }
}


// Custom checkbox for single checkbox with no text
.custom-single-checkbox{
    position: relative;
    overflow: hidden;
    display: block;
    .check-icon{
        height: 22px;
        width: 22px;
        border: 1px $gray-12 solid;
        display: block;
    }
    input{
        opacity: 0;
        position: absolute;
        &:checked + .check-icon{
            background-color: $danger;
            border-color: $danger;
            background-size: 12px 12px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -46 417.81333 417'%3E%3Cpath d='m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0' style='fill:%23FFF;'/%3E%3C/svg%3E");
        }
    }
}

.choice{
    position: relative;
    label{
        line-height: 22px !important;
        &:before{
            content: '';
            height: 22px;
            width: 22px;
            border: 1px $gray-12 solid;
            display: inline-block;
            flex-shrink: 0;
            position: relative;
            vertical-align: middle;
        }
        span{
            vertical-align: middle;
            margin-left: 8px;
        }
    }
    input{
        opacity: 0;
        position: absolute;
        &:checked + label:before{
            background-color: $danger;
            border-color: $danger;
            background-size: 12px 12px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -46 417.81333 417'%3E%3Cpath d='m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0' style='fill:%23FFF;'/%3E%3C/svg%3E");
        }
    }
}
.cms-home{
    .page-footer{
        // .usp-bar{
        //     display: none;
        // }
        .footer-link-section{
            background: $gray-2;
        }
    }
}
body:not(.cms-home){
    .page-footer{
        background: $white;
        .trustpilot-section{
            @include m-up(lg){
                display: none;
            }
        }
        .footer-link-section{
            background: $gray-2;
            // @include m-in(md,lg){
            //     background: $gray-2;
            // }
        }
    }
}



// Top border in mobile
// .checkout-cart-index{
//     .page-footer{
//         border-top: 1px solid $gray-4;
//     }
// }
.mobile-link,
.quick-link-toggle{
    border: 1px solid $gray-4;
    &.expanded{
        svg{
            transform: rotate(180deg);
            display: block;
        }
    }
}
#footer-quick-link{
    @include m-down(md){
        display: none;
    }
}
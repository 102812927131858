.paypal-express-review{
    .page-title-wrapper{
        display: none;
    }
}
// .price-summary{
//     width: 100%;
//     td,th{
//         padding: 5px;
//     }
//     td{
//         text-align: right;
//         padding-right: 0;
//     }
//     .price-summary{

//     }
// }
.loader{
    > img{
        max-width: 60px;
        margin: 0 auto;
    }
}
.loading-mask{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    > .loader{
        &:before{
            content: '';
            width: 60px;
            height: 60px;
            background-position: center;
            background-size: contain;
            background-image: url(../images/svgs/loader.svg);
            display: block;
            background-repeat: no-repeat;
        }
        img{
            display: none !important;
        }
    }
}

[data-role='main-css-loader']{
    display: none !important;
}
.page.messages{
    // @extend .container;
    padding: 0px 20px;
    @include m-up(lg){
        padding: 0px 40px;
    }
    // .message{
    //     margin: 0px;
    // }
}
.message{
    margin: 12px 0;
    padding: 8px 16px;
    display: block;
    position: relative;
    border: 1px solid transparent;
    &.success {
        background: lighten($success,45%);
        color: darken($success,10%);
        border-color: $success;
        a{
            color: darken($success,10%);
        }
    }
    &.error,
    &.notice {
        background: lighten($danger,46%);
        color: $danger;
        border-color: $danger;
        a{
            color: $danger;
        }
    }
    &.warning {
        background: lighten($warning,45%);
        color: darken($warning,23%);
        border-color: $warning;
        a{
            color: darken($warning,30%);
        }
    }
    &.info {
        background: lighten($info,60%);
        color: $info;
        border-color: $info;
        a{
            color: $info;
        }
    }
}

.paypal-express-review{
    .minicart-menu{
        pointer-events: none;
    }
    .usp-bar{
        display: none !important;
    }
}
.checkout-cart-index{
    .minicart-menu{
        pointer-events: none;
    }
    .trustpilot-section{
        display: block !important;
    }
}
#shipping-zip-form{
    .field{
        // flex: 0 0 50%;
        margin-bottom: 0;
        &:not(.shippingAddress-country_id){
            display: none;
        }
    }
}
#shopping-cart-table{
    .action-towishlist{
        display: none !important;
    }
}
.coupon-box{
    .action{
        // right: 0;
        // top: 50%;
        // transform: translateY(-50%);
        @include m-down(sm){
            position: relative !important;
            display: block !important;
            transform: none;
            width: 100%;
        }
    }
}

.cart-total{
    .data.table.totals{
        width: 100%;
        border-top: 1px solid $gray-4;
    }
    tr{
        border-bottom: 1px solid $gray-4;
    }
    th,td{
        padding: 16px 0;
        line-height: 22px;
    }
    th{
        font-weight: 400;
        text-align: left;
    }
    td{
        text-align: right;
    }
    .grand.totals{
        text-transform: uppercase;
        th,td{
            font-size: 18px;
            padding: 14px 0;
        }
    }
}
.cart-bottom-buttons{
    .checkout{
        width: 250px;
        height: 50px;
        order: 3;
        @include m-down(lg){
            width: 260px;
        }
        @include m-down(md){
            width: 50%;
        }
        @include m-down(sm){
            width: 100%;
            order: 1;
        }
    }
    .paypal{
        height: 50px;
        background-image: url('../images/svgs/paypal-logo.svg');
        background-repeat: no-repeat;
        background-size: 97px 26px;
        background-position: center;
        background-color: $gray-16;
        width: 250px;
        border-radius: 100px;
        overflow: hidden;
        order: 2;
        @include m-down(lg){
            width: 253px;
            // margin-right: 10px;
        }
        @include m-down(md){
            width: 50%;
        }
        @include m-down(sm){
            width: 100%;
            margin-right: 0;
        }
        input{
            opacity: 0;
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}


.checkout-cart-index{
    @include m-down(lg){
        .breadcrumbs{
            display: none;
        }
    }
}

.checkout-cart-index{
    input,select,textarea{
        @include m-up(md){
            font-size: 14px;
        }
    }
}

.cart-empty{
    text-align: center;
}


.disable-buttons{
    opacity: 0.5;
    pointer-events: none;
}

.mw-100px{
    min-width: 100px;
}
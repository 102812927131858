@each $color, $value in $text-colors {
  .text-#{$color} {
    color: $value !important;
    fill: $value !important;
  }
  a.text-#{$color}{
    @if $link-shade-percentage != 0 {
      &:hover,
      &:focus {
        color: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage)) !important;
        fill: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage)) !important;
      }
    }
  }
}

$breakpoints_custom: $grid-breakpoints;
@mixin m-up($breakpoint) {
  @if map-has-key($breakpoints_custom, $breakpoint) {
    $breakpoint-value: map-get($breakpoints_custom, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@mixin m-down($breakpoint) {
  @if map-has-key($breakpoints_custom, $breakpoint) {
    $breakpoint-value: map-get($breakpoints_custom, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

@mixin m-in($lower, $upper) {
  @if map-has-key($breakpoints_custom, $lower) and map-has-key($breakpoints_custom, $upper) {
    $lower-breakpoint: map-get($breakpoints_custom, $lower);
    $upper-breakpoint: map-get($breakpoints_custom, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @media (min-width: $lower) and (max-width: ($upper - 1)) {
      @content;
    }
  }
}
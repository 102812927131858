.catalog-category-view{
    background: $gray-2;
}

.offer-badge{
    border-radius: 4px;
    position: absolute;
    right: 15px;
    top:5px;
    z-index: 1;
    padding: 0 9px;
}
.category_sidebar{
    position: relative;
    .toolbar-products{
        position: absolute;
        right: 45px;
        top: 9px;
        margin: 0;
        padding: 0;
        display: flex;
        .sorter-options{
            text-transform: uppercase;
            font-size: 14px;
            line-height: 17px;
            border: none;
            background: transparent;
        }
        .modes{
            order: 2;
            margin-right: 0;
            margin-left: 46px;
            display: none;
            .modes-mode{
                border: none;
                &::before{
                    content: '';
                    height: 19px;
                    width: 20px;
                    background-image: url("data:image/svg+xml,%3Csvg  width='21px' height='19px' viewBox='0 0 20 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000' d='M20 0v9h-9V0h9zm-2 2h-5v5h5V2zm2 9v9h-9v-9h9zm-2 2h-5v5h5v-5zM9 0v9H0V0h9zM7 2H2v5h5V2zm2 9v9H0v-9h9zm-2 2H2v5h5v-5z'/%3E%3C/svg%3E");
                }
                &.mode-grid{
                    &.active{
                        &::before{
                            background-image: url("data:image/svg+xml,%3Csvg width='21px' height='19px' viewBox='0 0 20 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23878787' d='M20 0v9h-9V0h9zm-2 2h-5v5h5V2zm2 9v9h-9v-9h9zm-2 2h-5v5h5v-5zM9 0v9H0V0h9zM7 2H2v5h5V2zm2 9v9H0v-9h9zm-2 2H2v5h5v-5z'/%3E%3C/svg%3E");
                        }
                    }
                }
                &.mode-list{
                    &::before{
                        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 21 19' width='21px' height='19px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000' d='M21 .974v2H7v-2zm0 8v2H7v-2zm-17-8v2H0v-2zm0 8v2H0v-2zm17 8v2H7v-2zm-17 0v2H0v-2z'/%3E%3C/svg%3E ");
                    }
                }
                &.mode-list{
                    &.active{
                        &::before{
                            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 21 19' width='21px' height='19px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23878787' d='M21 .974v2H7v-2zm0 8v2H7v-2zm-17-8v2H0v-2zm0 8v2H0v-2zm17 8v2H7v-2zm-17 0v2H0v-2z'/%3E%3C/svg%3E ");
                        }
                    }
                }
            }
        }
        .toolbar-sorter{
            order: 1;
            padding: 5px 10px 0 0;
        }
        .pages{
            display: none !important;
        }
        .field{
            &.limiter{
                display: none;
            }
        }
    }
}
.amscroll-page-num{
    &.-amscroll-button{
        display: none;
    }
}
.amscroll-backtotop-block{
    &.-desktop-text{
        display: none;
    }
}

.sticky-desktop-filter{
    .filtered-sections{
        border-top: 1px solid $gray-4;
    }
    .page-header{
        box-shadow: none !important;
    }
    #am-shopby-container{
        margin-bottom: 10px;
    }
    .toolbar-products{
        display: none;
    }
    .block-content.filter-content{
        border: none !important;
    }
    .sidebar{
        position: fixed;
        background: $white;
        right: 0;
        left: 0;
        z-index: 99;
        box-shadow: 0 0 40px 0 rgba($black,0.3);
        top: 58px;
    }
    &.has-topbar{
        .sidebar{
            top: 97px;
        }
    }
    .column.main{
        padding-top: 57px;
    }
    .filter{
        margin: 0 !important;
    }
}
.block-content{
    &.filter-content{
        border:1px $gray-12 solid;
    }
    .filter-options{
        .filter-menu{
            position: relative;
            .filter-options-content{
                display: none;
                position: absolute;
                left: 0;
                background: $white;
                max-width: 100%;
                min-width: 300px;
                padding: 30px;
                box-shadow:0px 8px 15px 0 rgba(0,0,0,0.15);
                z-index: 999;
                a{
                    color: $black;
                    text-decoration: none;
                }
                .filter-options-container{
                    max-height: 328px;
                    overflow: auto;
                    @include customScroll();
                    .items{
                        .item{
                            position: relative;
                            min-height: 27px;
                            .filter-instance + input,
                            .am-input{
                                position: absolute;
                                opacity: 0;
                                height: 0;
                                width: 0;
                                display: block;
                                overflow: hidden;
                                z-index: -9999;
                            }
                            .filter-instance{
                                position: relative;
                                padding-left: 32px;
                                margin-bottom: 5px;
                                &::before{
                                    position: absolute;
                                    height: 22px;
                                    width: 22px;
                                    border: 1px $gray-12 solid;
                                    background: transparent;
                                    content: '';
                                    left: 0;
                                    top: 0;
                                }
                                &.amshopby-link-selected{
                                    &::before{
                                        background: $danger;
                                        border-color: $danger;
                                    }
                                    &:after{
                                        position: absolute;
                                        left: 5px;
                                        content: '';
                                        height: 12px;
                                        width: 12px;
                                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -46 417.81333 417'%3E%3Cpath d='m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0' style='fill:%23FFF;'/%3E%3C/svg%3E");
                                        text-align: center;
                                        top: 5px;
                                        background-repeat: no-repeat;
                                        background-size: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .filter-options-title{
                cursor: pointer;
                border-bottom: 1px transparent solid;
                &.active{
                    border-bottom-color: $black;
                    color: $gray-6;
                }
            }
            &:hover{
                @include m-up(md){
                    .filter-options-content{
                        display: block;
                    }
                    .filter-options-title{
                        box-shadow: 0 -15px 30px 0 rgba(0,0,0,0.10);
                        background: $white;
                        border-color: #fff;
                    }
                }
            }
            &.active{
                @include m-down(md){
                    .filter-options-content{
                        display: block;
                    }
                }
            }
            &:first-child{
                .filter-options-title {
                    padding-left: 30px !important;
                }
            }
        }
    }
}
.amshopby-filter-current{
    .amshopby-item{
        &.item{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            position: relative;
            padding: 0 20px 0 0;
        }
    }
    .amshopby-remove{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 8px;
        width: 8px;
        height: 8px;
        &::before, &::after {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $black;
            content: '';
        }
        &::before {
            transform: rotate(-45deg);
        }
        &::after {
            transform: rotate(45deg);
        }
    }
}
.catalog-topnav {
    .block{
        &.filter {
            display: none;
        }
    }
}
.amshopby-overlay-block{
    > .amshopby-loader{
        background-image: url(../images/svgs/loader.svg);
        background-size: 60px;
    }
}
.amshopby-slider-container{
    .ui-slider-horizontal{
        background: $gray-13;
        .ui-slider-range{
            background: $danger;
        }
        .ui-slider-handle{
            background: $danger;
            height: 10px;
            width: 10px;
            cursor: pointer;
        }
    }
}

@include m-down(xl) {
    .category_sidebar{
        .toolbar-products{
            .modes{
                margin-left: 5px;
            }
        }
    }
    .block-content {
        .filter-options{
            .filter-menu{
                &:first-child{
                    .filter-options-title {
                        padding-left: 10px !important;
                    }
                }
            }
        }
    }
}
@include m-down(lg) {
    .category_sidebar{
        .toolbar-products{
            display: none;
        }
    }
}
@include m-down(md) {
    .amshopby-overlay-block{
        position: fixed;
    }
    .catalog-category-view{
        .breadcrumbs{
            display: none;
        }
        &.sticky-menu{
            .page-header{
                box-shadow: none;
            }
        }
        &.page-products{
            .columns{
                position: static;
            }
        }
    }
    .category_sidebar{
        display: none;
    }

    body.filter-open{
        overflow: hidden !important;
        .page-main{
            position: relative;
            z-index: 99;
        }
        .mobile-filter {
            display:none !important;
        }
    }
    .mobile-filter{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
    }
    .block{
        &.filter {
            margin-bottom: 40px;
            position: fixed;
            background: #FFF;
            top: 0;
            right: 0;
            height: 100vh;
            overflow: auto;
            width: 100%;
            z-index: 22;
            margin: 0 !important;
            padding-bottom: 60px;
            .mobile-filter-button{
                position: fixed;
                bottom: 0;
                left: 0;
            }
        }
    }
    .block-content{
        &.filter-content{
            border: none;
            .filter-options{
                .filter-menu{
                    border-bottom: 1px $gray-4 solid;
                    &.active{
                        svg{
                            transform: rotate(180deg);
                        }
                    }
                    .filter-options-title{
                        &.active {
                            border-bottom-color:transparent;
                            span{
                                position: relative;
                                &::after{
                                    position: absolute;
                                    left: 0;
                                    bottom: -18px;
                                    height: 1px;
                                    width: 100%;
                                    background: $black;
                                    content: '';
                                }
                            }
                        }
                    }
                    &:first-child{
                        .filter-options-title{
                            padding-left: 0 !important;
                        }
                    }
                    .filter-options-content{
                        position: static;
                        box-shadow: none;
                        padding: 0;
                        min-width: 280px;
                    }
                    &:hover{
                        .filter-options-title {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}



// Filter hack
.page-with-filter{
    .filter-options{
        @include m-up(md){
            padding-right: 205px;
        }

    }
    .toolbar.toolbar-products{
        top:15px;
        position: absolute;
        right: 60px;
        z-index: 2;

        @include m-down(md){
            display: none;
        }
        @include m-down(lg){
            right: 40px;
        }
        &,
        .toolbar-sorter{
            padding: 0;
            margin: 0;
        }
        .sorter-action{
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 15px;
            overflow: hidden;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 16px;
            background-image: url('../images/svgs/converted-icon/icon-long-arrow-right.svg');
            transform: rotate(-90deg);
            &::before{
                display: none;
            }
            span{
                display: none;
            }
            &.sort-desc{
                transform: rotate(90deg);
            }
        }
        // .sorter-action,
        .sorter-label,
        .modes{
            display: none;
        }
        .sorter-options{
            height: auto;
            line-height: inherit;
            border: none;
            background-color: transparent;
            background-size: 12px;
            width: auto;
            padding-right: 20px;
            background-position: right center;
            margin: 0;
        }
    }

    &.catalog-category-view{
        .toolbar.toolbar-products{
            top: 14px;
        }
    }
    #amasty-shopby-product-list,
    .search.results{
        position: static;
        dl.block{
            display: none;
        }
    }
}


.catalogsearch-result-index{
    .toolbar.toolbar-products{
        top:40px;
    }
}


.contact-index-index .page-title-wrapper{
    display: none;
}
.contact-info{
    .time-value{
        border: 1px $gray-14 solid;
        border-radius: 4.5px;
    }
}
.contact{
    .fieldset {
         .field {
            .label {
                float: none;
                text-align: left;
            }
            .control {
                width: 100%;
                input{
                    border:1px $gray-14 solid;
                    padding: 17px 16px;
                    color: $gray-15;
                }
                textarea{
                    padding: 17px 16px;
                    height: 100px;
                }
            }
        }
    }
}